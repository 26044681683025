@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Gelano+Grotesque+alt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rasa&display=swap);
@import url(https://fonts.googleapis.com/css?family=Barlow&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css?family=Caveat&display=swap);
* {
	margin: 0;
	padding: 0;
	font-family: 'Gelano Grotesque Alt', sans-serif;
	font-weight: 100;
	font-size: 22px;
	line-height: 40px;
}
.header {
	font-family: 'Barlow Condensed';
	font-weight: lighter;
	text-transform: uppercase;
	font-size: 45px;
	-webkit-text-decoration: underline solid #d9e3da;
	        text-decoration: underline solid #d9e3da;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.section {
	margin: auto;
	padding-top: 60px;
	width: 100%;
}

@media (max-width: 650px) {
	* {
		font-size: 18px;
	}
	.header {
		font-size: 35px;
	}
}
@media (max-width:450){

}

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.Navbar_navWrapper__39FIa {
	position: sticky;
	top: 0;
	align-items: center;
	position: -webkit-sticky;
	width: 100%;
	height: 65px;
	background-color: #171511;
	display: flex;
}
.Navbar_wrapLogo__2jyRb {
	width: 50%;
	height: 65px;
	display: flex;
	justify-content: flex-start;
	margin: 0 0 0 2%;
}
.Navbar_wrapLogo__2jyRb > a {
	height: inherit;
	width: auto;
}
.Navbar_logo__2_lHF {
	height: 65px;
	width: 80px;
	cursor: pointer;
	background-color: #171511;
}
.Navbar_menuObjects__aIis9 {
	display: flex;
	justify-content: flex-end;
	width: 50%;
	height: 50px;
	margin-right: 2%; /*Byttet denne til padding*/
}

.Navbar_navElement__392Q8 {
	display: flex;
	margin: auto 0 auto auto;
}
.Navbar_navElement__392Q8:hover {
	text-decoration: underline;
}

.Navbar_atag__35TTu {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	font-family: 'Barlow Condensed';
	font-size: 22px;
}
.Navbar_abutton__3nkIX {
	color: #ffff;
	height: 100%;
	width: 100%;
}

.Navbar_navContact__2iMLd {
	height: 100%;
	width: 100%;
	height: inherit;
	border: 0;
	/*background-color: #d9e3da;*/
	background-color: transparent;
	color: #0000;
	list-style: none;
	font-family: 'Barlow Condensed';
}

.Navbar_navContact__2iMLd:hover > .Navbar_abutton__3nkIX {
	color: #ffff;
	text-decoration: underline;
}

.Navbar_buttonWrapper__sdynU {
	background-color: transparent;
	border: 0;
	color: #ffff;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	-webkit-clip-path: inset(50%);
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	padding: 0;
	margin: -1px;
}
.Navbar_toggleButton__1b2t0 {
	background-color: transparent;
	border: 0;
	color: #ffff;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	-webkit-clip-path: inset(50%);
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	padding: 0;
	margin: -1px;
}
.Navbar_profileWrapper__1HP7L {
	all: unset;
	width: 50%;
	margin-right: 2%;
}
.Navbar_toggleProfile__fyNiP {
	all: unset;
	width: 50px;
	height: wrap;
	float: right;
	background-color: transparent;
	border: 0;
	color: #ffff;
	float: right;
	display: flex;
	justify-content: right;
	font-size: 20px;
}
.Navbar_linkActive__2M-Ln {
	border-bottom: 10px solid red;
}
.Navbar_profileObjects__2bslS {
	position: fixed;
	top: 50px;
	width: 20%;
	left: 80%;
	height: 95vh;
	background-color: #171511;
	transition: height 1s ease-in-out;
	padding-top: 1%;
	overflow: scroll;
}
.Navbar_navElementProfile__2WjZb {
	margin: auto;
	width: 60%;
	display: flex;
	height: 10vh;
	background-color: transparent;
}
.Navbar_navElementProfile__2WjZb :hover {
	text-decoration: underline;
}
.Navbar_atagProfile__2CXVb {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	font-family: 'Barlow Condensed';
	font-size: 22px;
	margin: auto;
	background-color: transparent;
	border: 0;
}
@media (max-width: 800px) {

	.Navbar_menuObjects__aIis9 {
		background-color: transparent;
		border: 0;
		color: #ffff;
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		-webkit-clip-path: inset(50%);
		height: 1px;
		width: 1px;
		position: absolute;
		overflow: hidden;
		white-space: nowrap;
		padding: 0;
		margin: -1px;
		z-index: -10;
		transition: height 2s;
	}
	.Navbar_buttonWrapper__sdynU {
		all: unset;
		width: 50%;
		margin-right: 2%;
	}
	.Navbar_hideLogo__2Va4D {
		width: 50%;
		display: flex;
		justify-content: flex-start;
		margin: 0 0 0 2%;
	}
	.Navbar_toggleButton__1b2t0 {
		all: unset;
		width: 50px;
		height: wrap;
		float: right;
		background-color: transparent;
		border: 0;
		color: #ffff;
		float: right;
		display: flex;
		justify-content: right;
		font-size: 20px;
	}
	.Navbar_navWrapper__39FIa {
		width: 100%;
	}
	.Navbar_navWrapperActive__VqR39 {
		position: sticky;
		top: 0;
		align-items: center;
		position: -webkit-sticky;
		width: 100%;
		height: 65px;
		background-color: #171511;
		display: flex;
	}
	.Navbar_menuObjectsActive__3J12M {
		position: fixed;
		top: 50px;
		width: 40%;
		left: 60%;
		height: 95vh;
		background-color: #171511;
		transition: height 1s ease-in-out;
		padding-top: 1%;
		overflow: scroll;
	}
	.Navbar_navElement__392Q8 {
		margin: auto;
		width: 60%;
		display: flex;
		height: 18vh;
	}
	.Navbar_navContact__2iMLd {
		margin-top: 0;
		height: 18vh;
	}
	.Navbar_atag__35TTu {
		margin: auto;
	}

	.Navbar_toggleActive__3vI9s {
		position: relative;
		display: block;
	}
}
@media (max-width: 600px) {
	.Navbar_menuObjectsActive__3J12M {
		width: 100%;
		left: 0%;
		top: 65px;
	}
}

.Footer_wrap__7GkYx {
	background-color: #171511;
	margin-top: 20px;
	bottom: 100;
	display: flex;
	align-items:center;
	justify-content: space-between;
}
.Footer_menuObjects__379HC{
	display: flex;
	flex-direction:row;
	justify-content: space-between;
	width: 100%;
	list-style-type: none;

	
}
.Footer_navElement__rQ1aN{
	display:flex;
	margin: auto auto auto auto;
	
}
.Footer_atag__29awL{
	color:#ffff;
	
}


.About_header__3Via1 {
	text-align: center;
}

.About_about__1P7xL {
	display: flex;
	flex-direction: column;
}
.About_aboutText__gXYFR {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 7.5% 0 7.5%;
}
.About_aboutText__gXYFR img {
	width: 200px;
	height: 200px;
	object-fit: cover;
	display: flex;
	align-content: center;
	margin-bottom: 30px;
	margin-top: 30px;
}

.About_aboutText__gXYFR > h2 {
	font-size: 30px;
	font-family: 'Barlow Condensed';
	text-transform: uppercase;
	font-weight: 200;
	-webkit-text-decoration: underline solid #d9e3da;
	        text-decoration: underline solid #d9e3da;
	text-align: center;
}
.About_aboutText__gXYFR > h2 {
	margin-top: 35px;
}
.About_aboutText__gXYFR > p {
	text-align: center;
}

.Home_section_home__3Owd3 {
	/*background-image: url('../Pictures/logo192.png'); /*Endre dette til directory hvor bildet jeg skal bruke er.*/
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 120px);
}
.Home_logo__HI7OG {
	height: auto;
	width: auto;
	max-width: 100%;
	max-height: 100%;
}
.Home_wrapper__rLeS_ {
	margin: auto;
	height: 100%;
	width: 65%;
	margin: auto;
	display: block;
}
.Home_logoWrapper__2vCNB {
	width: 100%;
	margin: 0;
}
.Home_header__1fiuq {
	margin: auto;
	margin-top: 2%;
	text-align: center;
}
@media (max-width: 450px) {
	.Home_header__1fiuq {
		font-size: 25px;
	}
}
@media (max-width: 330px) {
	.Home_header__1fiuq {
		font-size: 15px;
	}
}

* {
	margin: 0;
	padding: 0;
}

.Contact_form__ztIv2 {
	height: 100%;
}
#Contact_contact__2gCkn {
	margin-top: 10%;
}
.Contact_headliner__w3OFB {
	text-align: center;
	margin-bottom: 2%;
}
.Contact_line__1C-Xq {
	width: 100%;
	height: 1px;
	margin: auto;
	background-color: black;
	margin-bottom: 20px;
}
.Contact_form__ztIv2 {
	height: 100%;
}
.Contact_fieldset__jK5QQ {
	display: flex;
	justify-content: space-evenly;
	-webkit-text-decoration: dotted;
	        text-decoration: dotted;
	border: none;
}
.Contact_fieldsetone__3nkqq {
	display: flex;
	flex-direction: column;
	justify-content: start;
}
.Contact_label__kNf0a {
	display: flex;
	flex-direction: column;
	width: 50%;
	padding-bottom: 5%;
	margin: auto;
}
.Contact_message__RwbrU {
	height: 100px;
	width: 100%;
}

.Contact_submitLabel__Z9oly {
	height: 100%;
	width: 100%;
}
.Contact_submit__39BKQ {
	width: 50%;
	margin: auto;
	background-color: #171511;
	color: #ffff;
	border: 2px solid #171511;
	border-radius: 20px;
	height: 100%;
	font-size: 25px;
}
.Contact_submit__39BKQ:hover {
	background-color: #ffff;
	border: 100px;
	color: #171511;
	border-color: #171511;
	border: 2px solid #171511;
	cursor: pointer;
	text-decoration: underline;
}

* {
	box-sizing: border-box;
}
* {
	margin: 0;
	padding: 0;
}
.Videos_hide__2JuvF {
	background-color: transparent;
	border: 0;
	color: #ffff;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	-webkit-clip-path: inset(50%);
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	padding: 0;
	margin: -1px;
}

.Videos_spinnerHeader__3hDOx{
	min-height: 100vh;
}


.Videos_grid__386RE {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1500px;
	margin: auto;
	padding-top: 1rem;
	list-style: none;
	padding: 0;
	margin-left: 0;
	height: 100%;
	overflow: visible;
	padding-bottom: 10%;
}

.Videos_grid__386RE iframe {
	max-width: 100%;
	align-items: center;
	justify-content: center;
}

.Videos_card__3w3Lx {
	margin: 1rem;
	flex-basis: 350px;
	padding: 1.5rem;
	text-align: left;
	color: inherit;
	text-decoration: none;
	border: 1px solid #eaeaea;
	border-radius: 10px;
	transition: color 0 15s ease, border-color 0 15s ease;
	object-fit: cover;
}

.Videos_frame__1b7pw {
	height: 70%;
	width: 90%;
	display: flex;
	justify-content: center;
	object-fit: cover;
	margin: auto;
}

.Videos_headers__3yIMK {
	text-align: center;
	padding-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 2rem;
}

@keyframes Spinner_spinner__1KA-_ {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.Spinner_spinnerContainer__3udEh{
	width:100%;
	margin-top:25%;
}
.Spinner_loadingSpinner__26Meh {
	width: 50px;
	height: 50px;
	border: 10px solid #f3f3f3; /* Light grey */
	border-top: 10px solid #383636; /* Black */
	border-radius: 50%;
	animation: Spinner_spinner__1KA-_ 1.5s linear infinite;
	margin-left:47%;
	align-items:center;
}

