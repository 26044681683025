@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap');

.navWrapper {
	position: sticky;
	top: 0;
	align-items: center;
	position: -webkit-sticky;
	width: 100%;
	height: 65px;
	background-color: #171511;
	display: flex;
}
.wrapLogo {
	width: 50%;
	height: 65px;
	display: flex;
	justify-content: flex-start;
	margin: 0 0 0 2%;
}
.wrapLogo > a {
	height: inherit;
	width: auto;
}
.logo {
	height: 65px;
	width: 80px;
	cursor: pointer;
	background-color: #171511;
}
.menuObjects {
	display: flex;
	justify-content: flex-end;
	width: 50%;
	height: 50px;
	margin-right: 2%; /*Byttet denne til padding*/
}

.navElement {
	display: flex;
	margin: auto 0 auto auto;
}
.navElement:hover {
	text-decoration: underline;
}

.atag {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	font-family: 'Barlow Condensed';
	font-size: 22px;
}
.abutton {
	color: #ffff;
	height: 100%;
	width: 100%;
}

.navContact {
	height: 100%;
	width: 100%;
	height: inherit;
	border: 0;
	/*background-color: #d9e3da;*/
	background-color: transparent;
	color: #0000;
	list-style: none;
	font-family: 'Barlow Condensed';
}

.navContact:hover > .abutton {
	color: #ffff;
	text-decoration: underline;
}

.buttonWrapper {
	background-color: transparent;
	border: 0;
	color: #ffff;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	-webkit-clip-path: inset(50%);
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	padding: 0;
	margin: -1px;
}
.toggleButton {
	background-color: transparent;
	border: 0;
	color: #ffff;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	-webkit-clip-path: inset(50%);
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	padding: 0;
	margin: -1px;
}
.profileWrapper {
	all: unset;
	width: 50%;
	margin-right: 2%;
}
.toggleProfile {
	all: unset;
	width: 50px;
	height: wrap;
	float: right;
	background-color: transparent;
	border: 0;
	color: #ffff;
	float: right;
	display: flex;
	justify-content: right;
	font-size: 20px;
}
.linkActive {
	border-bottom: 10px solid red;
}
.profileObjects {
	position: fixed;
	top: 50px;
	width: 20%;
	left: 80%;
	height: 95vh;
	background-color: #171511;
	transition: height 1s ease-in-out;
	padding-top: 1%;
	overflow: scroll;
}
.navElementProfile {
	margin: auto;
	width: 60%;
	display: flex;
	height: 10vh;
	background-color: transparent;
}
.navElementProfile :hover {
	text-decoration: underline;
}
.atagProfile {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	font-family: 'Barlow Condensed';
	font-size: 22px;
	margin: auto;
	background-color: transparent;
	border: 0;
}
@media (max-width: 800px) {

	.menuObjects {
		background-color: transparent;
		border: 0;
		color: #ffff;
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		-webkit-clip-path: inset(50%);
		height: 1px;
		width: 1px;
		position: absolute;
		overflow: hidden;
		white-space: nowrap;
		padding: 0;
		margin: -1px;
		z-index: -10;
		transition: height 2s;
	}
	.buttonWrapper {
		all: unset;
		width: 50%;
		margin-right: 2%;
	}
	.hideLogo {
		width: 50%;
		display: flex;
		justify-content: flex-start;
		margin: 0 0 0 2%;
	}
	.toggleButton {
		all: unset;
		width: 50px;
		height: wrap;
		float: right;
		background-color: transparent;
		border: 0;
		color: #ffff;
		float: right;
		display: flex;
		justify-content: right;
		font-size: 20px;
	}
	.navWrapper {
		width: 100%;
	}
	.navWrapperActive {
		position: sticky;
		top: 0;
		align-items: center;
		position: -webkit-sticky;
		width: 100%;
		height: 65px;
		background-color: #171511;
		display: flex;
	}
	.menuObjectsActive {
		position: fixed;
		top: 50px;
		width: 40%;
		left: 60%;
		height: 95vh;
		background-color: #171511;
		transition: height 1s ease-in-out;
		padding-top: 1%;
		overflow: scroll;
	}
	.navElement {
		margin: auto;
		width: 60%;
		display: flex;
		height: 18vh;
	}
	.navContact {
		margin-top: 0;
		height: 18vh;
	}
	.atag {
		margin: auto;
	}

	.toggleActive {
		position: relative;
		display: block;
	}
}
@media (max-width: 600px) {
	.menuObjectsActive {
		width: 100%;
		left: 0%;
		top: 65px;
	}
}
