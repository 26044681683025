.wrap {
	background-color: #171511;
	margin-top: 20px;
	bottom: 100;
	display: flex;
	align-items:center;
	justify-content: space-between;
}
.menuObjects{
	display: flex;
	flex-direction:row;
	justify-content: space-between;
	width: 100%;
	list-style-type: none;

	
}
.navElement{
	display:flex;
	margin: auto auto auto auto;
	
}
.atag{
	color:#ffff;
	
}

