* {
	margin: 0;
	padding: 0;
}

.form {
	height: 100%;
}
#contact {
	margin-top: 10%;
}
.headliner {
	text-align: center;
	margin-bottom: 2%;
}
.line {
	width: 100%;
	height: 1px;
	margin: auto;
	background-color: black;
	margin-bottom: 20px;
}
.form {
	height: 100%;
}
.fieldset {
	display: flex;
	justify-content: space-evenly;
	text-decoration: dotted;
	border: none;
}
.fieldsetone {
	display: flex;
	flex-direction: column;
	justify-content: start;
}
.label {
	display: flex;
	flex-direction: column;
	width: 50%;
	padding-bottom: 5%;
	margin: auto;
}
.message {
	height: 100px;
	width: 100%;
}

.submitLabel {
	height: 100%;
	width: 100%;
}
.submit {
	width: 50%;
	margin: auto;
	background-color: #171511;
	color: #ffff;
	border: 2px solid #171511;
	border-radius: 20px;
	height: 100%;
	font-size: 25px;
}
.submit:hover {
	background-color: #ffff;
	border: 100px;
	color: #171511;
	border-color: #171511;
	border: 2px solid #171511;
	cursor: pointer;
	text-decoration: underline;
}
