.section_home {
	/*background-image: url('../Pictures/logo192.png'); /*Endre dette til directory hvor bildet jeg skal bruke er.*/
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 120px);
}
.logo {
	height: auto;
	width: auto;
	max-width: 100%;
	max-height: 100%;
}
.wrapper {
	margin: auto;
	height: 100%;
	width: 65%;
	margin: auto;
	display: block;
}
.logoWrapper {
	width: 100%;
	margin: 0;
}
.header {
	margin: auto;
	margin-top: 2%;
	text-align: center;
}
@media (max-width: 450px) {
	.header {
		font-size: 25px;
	}
}
@media (max-width: 330px) {
	.header {
		font-size: 15px;
	}
}
