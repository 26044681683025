* {
	box-sizing: border-box;
}
* {
	margin: 0;
	padding: 0;
}
.hide {
	background-color: transparent;
	border: 0;
	color: #ffff;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	-webkit-clip-path: inset(50%);
	height: 1px;
	width: 1px;
	position: absolute;
	overflow: hidden;
	white-space: nowrap;
	padding: 0;
	margin: -1px;
}

.spinnerHeader{
	min-height: 100vh;
}


.grid {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1500px;
	margin: auto;
	padding-top: 1rem;
	list-style: none;
	padding: 0;
	margin-left: 0;
	height: 100%;
	overflow: visible;
	padding-bottom: 10%;
}

.grid iframe {
	max-width: 100%;
	align-items: center;
	justify-content: center;
}

.card {
	margin: 1rem;
	flex-basis: 350px;
	padding: 1.5rem;
	text-align: left;
	color: inherit;
	text-decoration: none;
	border: 1px solid #eaeaea;
	border-radius: 10px;
	transition: color 0 15s ease, border-color 0 15s ease;
	object-fit: cover;
}

.frame {
	height: 70%;
	width: 90%;
	display: flex;
	justify-content: center;
	object-fit: cover;
	margin: auto;
}

.headers {
	text-align: center;
	padding-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 2rem;
}
