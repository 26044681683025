@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gelano+Grotesque+alt&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rasa&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow&display=swap');
* {
	margin: 0;
	padding: 0;
	font-family: 'Gelano Grotesque Alt', sans-serif;
	font-weight: 100;
	font-size: 22px;
	line-height: 40px;
}
.header {
	font-family: 'Barlow Condensed';
	font-weight: lighter;
	text-transform: uppercase;
	font-size: 45px;
	text-decoration: underline solid #d9e3da;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.section {
	margin: auto;
	padding-top: 60px;
	width: 100%;
}

@media (max-width: 650px) {
	* {
		font-size: 18px;
	}
	.header {
		font-size: 35px;
	}
}
@media (max-width:450){

}
