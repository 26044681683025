@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinnerContainer{
	width:100%;
	margin-top:25%;
}
.loadingSpinner {
	width: 50px;
	height: 50px;
	border: 10px solid #f3f3f3; /* Light grey */
	border-top: 10px solid #383636; /* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
	margin-left:47%;
	align-items:center;
}
